// クリニック関連処理
var ZENJINKAI_CLINIC = ZENJINKAI_CLINIC || {};
// Google Map関連の処理
ZENJINKAI_CLINIC.MAP = {};
ZENJINKAI_CLINIC.MAP.init = function() {
  // デフォルト値の定義 - START /////////////////
  var _MARKER_ICON_URL = '/clinic/images/icon/icon_map_marker_';
  var _MARKER_ZINDEX_CURRENT = 101;
  var _MARKER_ZINDEX_CLINIC  = 100;
  var _MARKER_LABEL_DEFAULT = {
    'color'      : 'white',
    'fontWeight' : 'bold',
    'fontSize'   : '20px',
    'text'       : ' ' // 何らかの値がセットされていないとzIndexが無効なため、半角ブランクをセット
  };
  // デフォルト値の定義 - END ///////////////////


  // Private Function - START ///////////////////
  /**
   * クリニックデータの取得（対象domのattributeから）
   *
   */
  function _getClinicData($target, isCurrent, idx) {
    var searchType   = $target.data('search-type')?$target.data('search-type'):'1';
    var latitude     = $target.data('latitude');
    var longitude    = $target.data('longitude');
    var name         = $target.data('name');
    var zipcode      = $target.data('zipcode');
    var address      = $target.data('address');
    var url          = $target.data('url');
    var distance     = $target.data('distance')?$target.data('distance'):false
    var iconLabel    = $target.data('icon-label');
    var iconFileName = (isCurrent)?'current.png':((searchType == 2)?'hospital.png':'clinic.png');
    var iconUrl      = _MARKER_ICON_URL + iconFileName;

    return {
      'searchType': searchType,
      'latitude'  : latitude,
      'longitude' : longitude,
      'name'      : name,
      'zipcode'   : zipcode,
      'address'   : address,
      'clinicUrl' : url,
      'distance'  : distance,
      'iconLabel' : iconLabel,
      'iconUrl'   : iconUrl
    };
  }

  /**
   * 近隣のクリニック用のmarkerをセット
   *
   */
  function _setNeighborhoodClinics(map, infowin, neighborhoods, centerLat, centerLng) {

    var minLat = 999;
    var maxLat = 0;
    var minLng = 999;
    var maxLng = 0;

    // 現在表示しているクリニックも計算対象とする
    minLat = (minLat > centerLat)?centerLat:minLat;
    maxLat = (maxLat < centerLat)?centerLat:maxLat;
    minLng = (minLng > centerLng)?centerLng:minLng;
    maxLng = (maxLng < centerLng)?centerLng:maxLng;


    var neighborhoodMarkers = [];
    for(var idx=0; idx<neighborhoods.length; idx++) {
      var tmpMarker = _setMarker(map, neighborhoods[idx], idx, (_MARKER_ZINDEX_CLINIC - idx));
      // markerクリック時の処理
      google.maps.event.addListener(tmpMarker, 'click', function() {
        // 他に開いているinfowindowを非表示にする
        if (infowin) {
          infowin.close();
        }

        // infowindowにクリニック名／サイトリンク／拡大地図リンクを表示
        var code_info = '<div class="md_mapinfo">';
        code_info += '<p class="_name"><a href="' + this.clinicInfo.clinicUrl + '" class="mod_icon_blank" target="_blank">' + this.clinicInfo.name + '</a></p>';
        if (this.clinicInfo.distance) {
          code_info += '<p class="_distance">' + this.clinicInfo.distance + 'km</p>';
        }
        code_info += '<p class="_link"><a href="' + this.clinicInfo.clinicUrl + '" class="mod_link_text mod_icon_blank" target="_blank">クリニックサイト</a></p>';
        code_info += '<p class="_link is_lower"><a href="https://maps.google.co.jp/maps?q=' + encodeURIComponent(this.clinicInfo.name) + '" target="_blank" class="mod_link_text mod_icon_blank">Googleマップを見る</a></p>';
        code_info += '</div>';

        infowin.setContent(code_info);
        infowin.open(map, this);
      });

      // 中心を合わせるため、最大緯度・経度、最小緯度・経度を求める
      minLat = (minLat > neighborhoods[idx].latitude)?neighborhoods[idx].latitude:minLat;
      maxLat = (maxLat < neighborhoods[idx].latitude)?neighborhoods[idx].latitude:maxLat;
      minLng = (minLng > neighborhoods[idx].longitude)?neighborhoods[idx].longitude:minLng;
      maxLng = (maxLng < neighborhoods[idx].longitude)?neighborhoods[idx].longitude:maxLng;

    }


    // 最大緯度・経度、最小緯度・経度をオブジェクト化
    var rangeLatLng = {
      'minLat': minLat,
      'maxLat': maxLat,
      'minLng': minLng,
      'maxLng': maxLng,
    };

    // マップの移動
    _moveMap(map, rangeLatLng, centerLat, centerLng);

  }

  /**
   * markerをセット
   *
   */
  function _setMarker(map, data, idx, zIndex) {

    var name       = data['name'];
    var zipcode    = data['zipcode'];
    var address    = data['address'];
    var latitude   = data['latitude'];
    var longitude  = data['longitude'];
    var searchType = data['searchType'];
    var iconLabel  = data['iconLabel'];
    var iconUrl    = data['iconUrl'];
    var clinicUrl  = data['url'];

    // ラベルオプションの設定
    var markerLabel = _MARKER_LABEL_DEFAULT;
    if (iconLabel) {
      markerLabel['text'] = iconLabel;
    }

    // markerの生成
    var marker = new google.maps.Marker({
      position: new google.maps.LatLng(latitude, longitude),
      map: map,
      icon: {
        url: iconUrl,
        labelOrigin: new google.maps.Point(22, 22)
      },
      label: markerLabel,
      zIndex: zIndex
    });

    marker.clinicInfo    = data;
    marker.defaultZindex = zIndex;

    return marker;
  }

  /**
   * 地図の中心とzoomの調整
   *
   */
  function _moveMap(map, latlng, centerLat, centerLng) {
    // 北西端の座標を設定
    var sw = new google.maps.LatLng(latlng.maxLat, latlng.minLng);
    // 東南端の座標を設定
    var ne = new google.maps.LatLng(latlng.minLat, latlng.maxLng);

    // 範囲を設定
    var bounds = new google.maps.LatLngBounds(sw, ne);
    // markerが全て収まるように地図の中心とズームを調整して表示
    map.fitBounds(bounds);

    // 中心点の移動
    // if (centerLat, centerLng) {
    //   map.panTo(new google.maps.LatLng(centerLat, centerLng));
    // }
  }
  // Private Function - END /////////////////////

  return {
    execute: function() {
      // アクセスマップの生成
      this.initMaps();
    },
    initMaps: function() {
      // アクセスマップの生成
      $('.js_map_access').each(function() {

        var $map = $(this);
        var latitude  = $map.data('latitude');
        var longitude = $map.data('longitude');

        // 近隣のクリニックデータの取得
        var neighborhoods  = [];
        $map.find('.js_map_neighborhood').each(function(idx) {
          var neighborhood = _getClinicData($(this), false, idx);
          neighborhoods.push(neighborhood);
        });

        // MAPの初期化
        var map = new google.maps.Map($map.get(0), {
//          zoom: 16,
          zoom: 14,
          center: new google.maps.LatLng(latitude, longitude),
          mapTypeId: google.maps.MapTypeId.ROADMAP,
          scrollwheel : false
        });

        // 現在のクリニックのmarkerをセット
        var markerLabel = _MARKER_LABEL_DEFAULT;
        var marker = new google.maps.Marker({
          position: new google.maps.LatLng(latitude, longitude),
          map: map,
          icon: {
            url: _MARKER_ICON_URL + 'current.png',
            labelOrigin: new google.maps.Point(22, 22)
          },
          label: markerLabel,
          zIndex: _MARKER_ZINDEX_CURRENT
        });

        if (neighborhoods.length > 0) {
          // markerクリック時の処理
          google.maps.event.addListener(marker, 'click', function() {
            // 他に開いているinfowindowを非表示にする
            if (infowin) {
              infowin.close();
            }
          });

          // 近隣のクリニックの初期化
          var infowin = new google.maps.InfoWindow();
          _setNeighborhoodClinics(map, infowin, neighborhoods, latitude, longitude);
        }

      });
    }
  }
};
